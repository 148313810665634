@font-face {
  font-family: OpenSans;
  src: url('../assets/fonts/OpenSans-Regular.ttf');
}


.long-title > span {
    font-size:54px;
    color:white;
}
.long-title{
     display: flex;
    flex-direction: column;
}

.back-btn{
    background: #e46e44;
    border-radius: 4rem;
    padding:8px;
    text-align: center;
    min-width: 100px;
    color:white;
}
cf-chat-response.user text>p{
    background:  #0d1267!important;
}

.cf-button:focus, .cf-button:focus:not([checked=checked]), .cf-button:hover, .cf-button:hover:not([checked=checked]) {
     background:  #0d1267!important;
}

#conversational-form > div > cf-input > div > cf-input-button {
     background:  #0d1267!important;
}

* {

    font-family: OpenSans;
    margin:0;
}
.conversational-form{
    position:relative!important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin:30px 30px;
}

header{
    width: 100%;
    background-color: #0d1267;
    // background-image: url(https://startfinance.ro/wp-content/uploads/2020/08/ptb-bg-7.svg);
    // background-position: left bottom;
    // background-repeat: no-repeat;
    // border-width: 0px 0px 0px 0px;
    // border-color: #e2e2e2;
    // border-style: solid;
    // -webkit-background-size: cover;
    // -moz-background-size: cover;
    // -o-background-size: cover;
    // background-size: cover;
    min-height:80px;
    padding:30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap:30px;
    a {
        color: white;

    }
    a:visited{
        color:white;
    }
}


footer{
    width: 100%;
    background-color: #0d1267;
    min-height:60px;
    display: flex;
    padding:30px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    span {
        margin:30px;
        color:white;
    }
    span > a {
        color:#f86635;
    }
}




main{
    // background:red;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
}
.form-wrapper{
    // background: blue;
    width: 100%;
    min-height: 800px;
    max-width: 1000px;
    display: flex;
    margin:30px;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    gap:30px;
    // margin-top: 900px;
}

.logo{
    margin: 30px;
    width:120px;
    height:120px;
}

.conversational-form .conversational-form-inner{
    position:initial!important;
    max-width: 800px;
    height: auto;
}


@media screen and (max-width: 900px) {
    header{
        flex-direction: column;
        gap:10px;
    }
    .logo{
        margin:0px;
    }
    .form-wrapper{
        gap:10px;
    }
    .form-wrapper > p {
        margin-left:30px;
        margin-right:30px;
    }
    footer{
        justify-content: center;
    }
}

@media screen and (max-width: 400px) {
     .long-title > span{
        font-size:26px;
    }
}


.conversational-form--enable-animation cf-list-button:before{
    background: #e5e6ea!important;
    border: solid 1px black;
    border-radius: 4px;;
}